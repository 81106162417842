<template>
  <section class="new-folder-view">
    <b-loading :active="isLoading" :is-full-page="false"></b-loading>

    <b-modal v-model="showTemplateImport" has-modal-card>
      <template-import
        :companyName="companyName"
        :merchantUniqueName="merchantUniqueName"
        @import="importTemplate($event)"
      ></template-import>
    </b-modal>

    <ValidationObserver ref="observer" tag="div" class="block">
      <section class="section has-background-eurotext-light block">
        <div>
          <b-field class="v-center-fields" grouped>
            <b-input-with-validation
              vid="insuranceName"
              :name="$t('policies.folder.insurance.name.label')"
              :input-attrs="{
                placeholder: $t('policies.folder.insurance.name.placeholder'),
              }"
              :field-attrs="{ expanded: true }"
              :fieldAttrs="{ expanded: true }"
              rules="required"
              :value="insuranceName.toUpperCase()"
              @input="insuranceName = $event.toUpperCase()"
            >
              <template #label
                ><span class="has-text-light">{{
                  $t("policies.folder.insurance.name.label")
                }}</span></template
              >
            </b-input-with-validation>

            <b-input-with-validation
              vid="policyName"
              :name="$t('policies.name.label')"
              :input-attrs="{
                placeholder: $t('policies.name.placeholder'),
              }"
              :fieldAttrs="{ expanded: true }"
              rules="required"
              :value="policyName.toLowerCase()"
              @input="policyName = $event.toLowerCase()"
            >
              <template #label
                ><span class="has-text-light">{{
                  $t("policies.name.label")
                }}</span></template
              >
            </b-input-with-validation>

            <b-input-with-validation
              vid="version"
              :name="$t('policies.folder.version.label')"
              v-model="version"
              :input-attrs="{
                placeholder: $t('policies.folder.version.placeholder'),
                number: true,
              }"
              :fieldAttrs="{ expanded: false }"
              rules="numeric"
            >
              <template #label
                ><span class="has-text-light">{{
                  $t("policies.folder.version.label")
                }}</span></template
              >
            </b-input-with-validation>
          </b-field>

          <b-field class="v-center-fields" grouped>
            <b-input-with-validation
              vid="timeout"
              :name="$t('policies.folder.timeout.label')"
              v-model="timeoutDays"
              :input-attrs="{
                placeholder: $t('policies.folder.timeout.placeholder'),
              }"
              :fieldAttrs="{ expanded: false }"
              rules="required|numeric|min_value:1"
            >
              <template #label
                ><span class="has-text-light">{{
                  $t("policies.folder.timeout.label")
                }}</span></template
              >
            </b-input-with-validation>

            <b-input-with-validation
              vid="pec"
              :name="$t('policies.folder.pec.label')"
              v-model="pecAddr"
              :input-attrs="{
                placeholder: $t('policies.folder.pec.placeholder'),
              }"
              :fieldAttrs="{ expanded: false }"
              rules="required|email"
            >
              <template #label
                ><span class="has-text-light">{{
                  $t("policies.folder.pec.label")
                }}</span></template
              >
            </b-input-with-validation>

            <b-field class="is-flex is-flex-direction-column">
              <template #label
                ><span class="has-text-light">{{
                  $t("policies.folder.type.label")
                }}</span></template
              >
              <b-radio
                v-for="ft in folderTypes"
                :key="ft.id"
                v-model="selectedFolderType"
                :native-value="ft"
                type="is-white"
              >
                <span class="has-text-light">{{
                  $t(`policies.folder.type.${ft.id}`)
                }}</span>
              </b-radio>
            </b-field>

            <ValidationProvider
              vid="isPrivate"
              :name="$t('policies.private')"
              rules="required"
              v-slot="{ errors }"
              slim
            >
              <b-field
                class="is-flex is-flex-direction-column"
                :type="{ 'is-danger': errors[0] }"
                :message="errors.length > 0 ? errors : null"
              >
                <template #label
                  ><span class="has-text-light">{{
                    $t("policies.folder.visibility")
                  }}</span></template
                >
                <b-radio
                  v-model="isPrivate"
                  :native-value="true"
                  type="is-white"
                >
                  <span class="has-text-light">{{
                    $t("policies.private")
                  }}</span>
                </b-radio>
                <b-radio
                  v-model="isPrivate"
                  :native-value="false"
                  type="is-white"
                >
                  <span class="has-text-light">{{
                    $t("policies.public")
                  }}</span>
                </b-radio>
              </b-field>
            </ValidationProvider>
          </b-field>

          <b-field class="v-center-fields" grouped>
            <b-field class="is-flex is-flex-direction-column">
              <template #label
                ><span class="has-text-light">{{
                  $t("policies.pay_merchant.top_dropdown_label")
                }}</span>
              </template>

              <b-dropdown
                :scrollable="true"
                :max-height="150"
                aria-role="list"
                id="pay-merchant-dropdown"
                v-model="currentPayMerchant"
                :disabled="payMerchantsDropdownDisabled"
              >
                <template #trigger>
                  <b-button icon-right="menu-down">
                    <span v-if="currentPayMerchant != null">{{
                      currentPayMerchant["show_name"]
                    }}</span>
                    <span v-else>{{
                      $t("policies.pay_merchant.empty_dropdown_label")
                    }}</span>
                  </b-button>
                </template>
                <b-dropdown-item
                  v-for="(merchant, index) in this.payMerchants"
                  :key="index"
                  :value="merchant"
                  aria-role="listitem"
                >
                  {{ merchant.show_name }}
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
          </b-field>
        </div>
      </section>
    </ValidationObserver>

    <ValidationObserver ref="templObserver" tag="div" class="block">
      <section class="block">
        <b-tabs
          v-model="activeTab"
          position="is-left"
          type="is-toggle"
          expanded
        >
          <b-tab-item
            v-for="(type, index) in templateTypes"
            :key="index"
            :value="type.key"
            :label="$t(`policies.${type.key}.label`)"
          >
            <div class="contaier has-background-eurotext-light">
              <pdf-editor
                :template="templates[type.key]"
                :folder-name="folderName"
                :group-type="type"
                :signerRoles="roles"
                :name="`${folderName}.${type.name}`"
                :enabled="templates[type.key] ? true : null"
                @input="onFileChange($event, type)"
                @save="saveTemplate($event, type)"
                @deleteTemplate="removeTemplate(type)"
                @metadata="onMetadataChange($event, type)"
                ref="template"
              >
                <template #beforeName> </template>

                <template #importButton>
                  <button
                    type="button"
                    class="button is-primary templ-button-equal-w"
                    @click="showTemplateImport = true"
                  >
                    <b-icon icon="download"></b-icon>
                    <span>{{ $t("policies.template.buttons.import") }}</span>
                  </button>
                </template>
              </pdf-editor>
            </div>
          </b-tab-item>
        </b-tabs>
      </section>
    </ValidationObserver>

    <div class="block level">
      <div class="level-item">
        <b-button type="is-primary" @click="finalizeFolder" size="is-medium">{{
          $t(`policies.folder.button.upload`)
        }}</b-button>
      </div>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import PdfEditor from "@/components/policies/Pdf";
import { policiesService } from "@/services";
import { templateMixin } from "@/mixins";
import { folderTypes, templateTypes } from "@/helpers/constants";
import BInputWithValidation from "@/components/inputs/BInputWithValidation.vue";
import TemplateImport from "./TemplateImport.vue";

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // reader.readAsDataURL(file);
    reader.readAsBinaryString(file);

    reader.onload = () => {
      // let aux = reader.result.split(",");
      // resolve(aux[aux.length - 1]);
      // let b64 = btoa(reader.result);
      // console.log(b64);
      let unit8array = new TextEncoder().encode(reader.result);
      let b64 = btoa(new TextDecoder().decode(unit8array));
      // console.log(b64);
      resolve(b64);
    };
    reader.onerror = (error) => reject(error);
  });
}

// const templateTypes = [
//   { key: "COMPANY", name: "C" },
//   { key: "AGENCY", name: "A" },
//   { key: "CUSTOMER", name: "U" },
// ];

export default {
  mixins: [templateMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    BInputWithValidation,
    PdfEditor,
    TemplateImport,
  },
  props: {
    companyName: {
      type: String,
      required: true,
    },
    folderData: {
      type: Object,
      required: false,
      default: null,
    },
    modify: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: null,
      isCompanyModalActive: false,
      isUploading: false,
      isPrivate: null,
      policyName: "",
      insuranceName: "",
      version: "",
      timeoutDays: this.$config.insurance.folder.timeout_days,
      pecAddr: "",
      selectedMerchant: null,
      roles: [],
      templates: {},
      templateTypes: templateTypes,
      // templateTypes: ["C", "A", "U"],
      folderTypes: folderTypes,
      selectedFolderType: folderTypes[0],
      currFolderData: null,
      showTemplateImport: false,
      currentPayMerchant: null,
      payMerchantsDropdownDisabled: false,
    };
  },
  computed: {
    isLoading() {
      return this.isUploading;
    },
    activeTabType() {
      return this.templateTypes.find((p) => p.key === this.activeTab);
    },
    folderName() {
      let str = "";
      let suffix = "";
      if (this.insuranceName) str = str.concat(`${this.insuranceName}`);
      if (this.policyName) str = str.concat(`.${this.policyName}`);
      if (this.version) str = str.concat(`.${this.version}`);
      if (this.isPrivate) str = str.concat(`.${this.merchantName}`);
      return str.concat(suffix);
    },
    // merchantUniqueName() {
    //   if (this.merchant) return this.merchant["unique_name"];
    //   else return "";
    // },
  },
  methods: {
    buildTeamplateName(templateType) {
      return `${this.folderName}.${templateType}`;
    },
    async finalizeFolder() {
      let folderId = this.currFolderData ? this.currFolderData["id"] : null;
      if (!folderId) {
        this.$buefy.snackbar.open({
          message: this.$t("policies.folder.finalize.error.no_template"),
          type: "is-danger",
          actionText: "OK",
          indefinite: false,
          duration: 6000,
          position: "is-top-right",
          queue: false,
          // container: "#mainCol",
        });
        return;
      }

      // All templates must have been enabled or disabled
      const valid = await this.$refs.templObserver.validate();
      if (!valid) {
        this.$buefy.snackbar.open({
          message: this.$t("policies.folder.finalize.error.template_on_off"),
          type: "is-danger",
          actionText: "OK",
          indefinite: false,
          duration: 6000,
          position: "is-top-right",
          queue: false,
          // container: "#mainCol",
        });
        return;
      }

      // All templates enabled must have been saved or disabled
      for (let t of this.$refs.template) {
        if (!t.isSavedOrDisabled) {
          this.$buefy.snackbar.open({
            message: this.$t(
              "policies.folder.finalize.error.not_saved_or_disabled",
              { template: t.name }
            ),
            type: "is-danger",
            actionText: "OK",
            indefinite: false,
            duration: 6000,
            position: "is-top-right",
            queue: false,
            // container: "#mainCol",
          });
          return;
        }
      }

      this.$buefy.dialog.confirm({
        title: this.$t("policies.folder.finalize.dialog.title"),
        message: this.$t("policies.folder.finalize.dialog.msg", {
          folder: this.folderName,
        }),
        cancelText: this.$t("buttons.cancel"),
        confirmText: this.$t("buttons.continue"),
        onConfirm: () => {
          this.isUploading = true;
          policiesService
            .finalizeGroup(
              this.companyName,
              folderId,
              this.folderName,
              !this.isPrivate,
              this.timeoutDays,
              this.pecAddr,
              this.selectedFolderType.id
            )
            .then(() => {
              this.$buefy.toast.open({
                message: this.$t("policies.folder.finalize.success"),
                type: "is-success",
                position: "is-top-right",
                // container: "#mainCol",
              });
            })
            .finally(() => {
              close();
              this.isUploading = false;
            });
        },
      });
    },
    onFileChange(eventData, type) {
      if (this.templates[type.key])
        this.$set(this.templates[type.key], "file", eventData);
      else {
        let obj = { file: eventData };
        this.$set(this.templates, type.key, obj);
      }
    },
    onMetadataChange(eventData, type) {
      if (this.templates[type.key])
        this.$set(this.templates[type.key], "metadata", eventData);
      else {
        let obj = { metadata: eventData };
        this.$set(this.templates, type.key, obj);
        // this.templates[type.key] = obj;
      }
    },
    async saveTemplate(eventData, type) {
      const { companyName, folderName, templates } = this;

      try {
        const typeId = type.key;
        const valid = await this.$refs.observer.validate();
        console.log(valid);
        if (!valid) {
          this.$buefy.toast.open({
            message: this.$t("policies.folder.finalize.error.missing_params"),
            type: "is-danger",
            position: "is-top-right",
            duration: 4000,
            // container: "#mainCol",
          });
          console.log("Folder name fields not valid");
          return; // TODO: popup inserire tutte le informazioni del folder
        }

        if (!templates[typeId].file) {
          console.log("tempalte file not present");
          return; //TODO: popup aggiungere file al template
        }

        this.isUploading = true;

        let folderId = this.currFolderData ? this.currFolderData["id"] : null;
        let groupResponse = undefined;
        try {
          groupResponse = await policiesService.createTemplateGroup(
            folderId,
            companyName,
            folderName,
            this.merchantUniqueName,
            !this.isPrivate,
            this.timeoutDays,
            this.pecAddr,
            this.selectedFolderType.id,
            this.currentPayMerchant ? this.currentPayMerchant.nickname : null
          );
        } catch (error) {
          if (error.response && error.response.data) {
            if (error.response.data.code === -10110) {
              this.$buefy.toast.open({
                message: this.$t("policies.folder.error.already_exists", {
                  name: folderName,
                }),
                type: "is-danger",
                duration: 4000,
              });
            } else if (error.response.data.code === -10112) {
              this.$buefy.toast.open({
                message: this.$t("policies.folder.error.already_finalized", {
                  name: folderName,
                }),
                type: "is-danger",
                duration: 4000,
              });
            }
          }
          throw { ...error, handled: true };
        }

        console.log("group response", groupResponse);

        folderId = groupResponse["id"];
        this.currFolderData = groupResponse;

        const templateName = this.buildTeamplateName(type.name);

        let file64 = null;
        let metadata64 = null;
        file64 = await fileToBase64(templates[typeId].file);
        metadata64 = btoa(eventData);
        let templateId = templates[typeId].id;
        let templateRes = undefined;
        try {
          // {id: Number, name: String, date: String, owner: String, signers_nr: Number}
          templateRes = await policiesService.createTemplate(
            templateId,
            companyName,
            templateName,
            this.merchantUniqueName,
            metadata64,
            file64,
            !this.isPrivate
          );
        } catch (error) {
          if (error.response && error.response.data) {
            if (error.response.data.code === -10110) {
              this.$buefy.toast.open({
                message: this.$t("policies.template.error.already_exists", {
                  name: folderName,
                }),
                type: "is-danger",
                duration: 4000,
              });
            } else if (error.response.data.code === -10112) {
              this.$buefy.toast.open({
                message: this.$t("policies.template.error.already_finalized", {
                  name: folderName,
                }),
                type: "is-danger",
                duration: 4000,
              });
            }
          }
          throw { ...error, handled: true };
        }
        console.log("template response", templateRes);

        this.templates[typeId].id = templateRes.id;

        let response = this.associateTemplatesToFolder();

        console.log("Upload completed", response);

        // Notify user
        this.$buefy.toast.open({
          message: this.$t("upload.messages.upload_success"),
          type: "is-success",
          duration: 4000,
          position: "is-top-right",
          // container: "#mainCol",
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isUploading = false;
      }
    },
    async associateTemplatesToFolder() {
      // const typeId = type.key;
      var response = "";
      if (this.currFolderData) {
        // const templId = this.templates[typeId]
        //   ? this.templates[typeId].id
        //   : null;
        response = await policiesService.associateTemplatesToGroup(
          this.companyName,
          this.currFolderData.id,
          this.templates[this.templateTypes[0].key]
            ? this.templates[this.templateTypes[0].key].id
            : null, // company
          this.templates[this.templateTypes[1].key]
            ? this.templates[this.templateTypes[1].key].id
            : null, // agency
          this.templates[this.templateTypes[2].key]
            ? this.templates[this.templateTypes[2].key].id
            : null // customer
        );
      } else {
        response = "No current folder";
      }
      return response;
    },
    removeTemplate(type) {
      console.log(this.templates[type.key]);
      if (this.templates[type.key]) {
        this.$delete(this.templates, type.key);
        // response = await policiesService.associateTemplatesToGroup(
        //   this.companyName,
        //   this.currFolderData.id,
        //   this.templates[0] ? this.templates[0].id : null, // company
        //   this.templates[type.key] ? this.templates[type.key].id : null, // agency
        //   this.templates[type.key] ? this.templates[type.key].id : null // customer
        // );
        this.associateTemplatesToFolder().then((res) => console.log(res));
      }
    },
    /**
     * Import template from server.
     * It's used to modify a folder and/or create a folder starting from existing templates.
     * @param {object} template - The template to import.
     * @param {string|number} template.id - The id of the template to import.
     * @param {object|undefined} [type] - The type that will be the new imported template. If it's undefined the current visible type is used.
     */
    importTemplate(template, type = undefined) {
      var t = type;
      if (type === undefined) {
        t = this.activeTabType;
      }
      console.log(t);

      policiesService
        .fetchTemplateMetadata(this.companyName, template.id)
        .then((res) => {
          // const meta = {};
          // res.forEach((page) => {
          //   meta[page.page] = {
          //     pageSignatures: page.signatureSlots,
          //     pageCheckboxes: page.checkboxSlots,
          //   };
          // });
          this.onMetadataChange(res, t);
        });
      policiesService
        .fetchTemplateSamplePdf(this.companyName, template.id)
        .then((res) => {
          // const fData = atob(res);
          const fData = Uint8Array.from(
            atob(res)
              .split("")
              .map((char) => char.charCodeAt(0))
          );
          const file = new File([fData], `${template.name}.pdf`, {
            type: "application/pdf",
          });
          this.onFileChange(file, t);
        });
    },
  },
  mounted() {
    // async/await needed to prevent this.currentPayMerchant ending up null
    this.fetchPayMerchants().then(() => {
      console.log("payMerchants: " + this.payMerchants);
      if (this.payMerchants && this.payMerchants.length > 0) {
        this.currentPayMerchant = this.payMerchants[0];
        this.payMerchantsDropdownDisabled = false;
      } else {
        this.currentPayMerchant = null;
        this.payMerchantsDropdownDisabled = true;
      }
    }); // templateMixin
  },
  created() {
    policiesService.fetchRoles(this.companyName).then((res) => {
      this.roles = res;
    });
    if (this.folderData) {
      for (let template of this.folderData["templates"]) {
        console.log(template);
        let found = templateTypes.find((p) => p.key === template["type"]);
        if (found) {
          if (this.modify) {
            if (this.templates[found.key])
              this.$set(this.templates[found.key], "id", template.id);
            else {
              let obj = { id: template.id };
              this.$set(this.templates, found.key, obj);
            }
          }

          this.importTemplate(template, found);
          this.activeTab = found.key;
        }
      }

      if (this.modify) {
        this.currFolderData = this.folderData;
        let fname = this.folderData.name;
        const arr = fname.split(".");
        this.insuranceName = arr[0];
        this.policyName = arr[1];
        if (arr[2] && Number(arr[2])) this.version = arr[2];
        this.isPrivate = !this.folderData["is_public"];
        this.timeoutDays = this.folderData["timeout_signature_days"];
        this.pecAddr = this.folderData["pec_address"];
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tab-section {
  margin-top: 4rem;
}
</style>
