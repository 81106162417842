<template>
  <div class="modal-card" style="width: 100%">
    <div class="modal-card-body is-titleless">
      <div class="media">
        <div class="media-left">
          <b-icon icon="alert-circle" :type="type" size="is-large"></b-icon>
        </div>

        <div class="media-conent">
          <slot>
            <p class="mb-4">
              {{
                $t("policies.rao.revocation.confirm.msg", {
                  user: user,
                })
              }}
            </p>
          </slot>

          <b-field v-if="checkbox">
            <b-checkbox v-model="cbValue" type="is-primary">
              <slot name="checkbox-text">
                {{ $t("policies.rao.revocation.confirm.checkbox") }}
              </slot>
            </b-checkbox>
          </b-field>
        </div>
      </div>
    </div>

    <div class="modal-card-foot">
      <button class="button" @click="$emit('cancel')">
        {{ $t("buttons.cancel") }}
      </button>
      <button class="button" :class="type" @click="onConfirm">
        {{ $t("buttons.continue") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkbox: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String,
      required: false,
      defulat: "",
    },
    user: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      cbValue: true,
    };
  },
  methods: {
    onConfirm() {
      this.$emit("confirm", this.cbValue);
    },
  },
};
</script>

<style>
.modal-card-body.is-titleless {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.modal-card-foot {
  justify-content: flex-end;
}
</style>
