<template>
  <div class="root-menu is-flex-column">
    <b-menu>
      <!-- Search field -->
      <b-field class="">
        <b-input
          v-model="search"
          :placeholder="$t('menu.search.placeholder')"
          type="search"
          icon="magnify"
        >
        </b-input>
      </b-field>

      <!-- menu items -->
      <b-menu-list>
        <!-- <b-menu-item
          v-for="item in menuItems"
          :key="item.id"
          tag="a"
          :icon="$config.icons.menu[item.id]"
          @click="routeClick({ path: rootPath + '/' + item.id })"
        > -->
        <b-menu-item
          v-for="item in menuItems"
          :key="item.id"
          tag="a"
          :icon="$config.icons.menu[item.id]"
          @click="routeClick({ path: rootPath + '/' + item.id })"
        >
          <template slot="label"
            ><span class="c-txt-ml">{{
              $t(`menu.label.${item.id}`)
            }}</span></template
          >
        </b-menu-item>
      </b-menu-list>

      <slot name="beforeCompany">
        <hr class="hr is-success" />
      </slot>

      <b-menu-list>
        <template #label>
          <span class="has-text-white">{{
            $t("menu.company_list_label")
          }}</span>
        </template>

        <b-menu-item
          v-for="company in filteredClasses"
          :key="company.name"
          :icon="$config.icons.menu._company"
          :expanded="
            userSchemas.length === 1 || $route.params.company === company.name
          "
          :active="$route.params.company === company.name"
        >
          <template slot="label" slot-scope="props">
            <span class="c-txt-ml">{{
              company.label ? company.label : company.name
            }}</span>
            <b-icon
              class="is-pulled-right"
              :icon="props.expanded ? 'menu-down' : 'menu-up'"
            ></b-icon>
          </template>

          <b-menu-item
            v-for="item in company.menuItems"
            :key="item.id"
            tag="a"
            @click="
              routeClick({ path: `${rootPath}/${company.name}/${item.id}` })
            "
          >
            <!-- <b-menu-item
            v-for="item in company.menuItems"
            :key="item.id"
            tag="router-link"
            :to="{ path: `${rootPath}/${company.name}/${item.id}` }"
          > -->
            <template slot="label">
              <b-icon
                :icon="$config.icons.menu[item.id]"
                size="is-small"
                type=""
              ></b-icon>
              <span class="c-txt-ml">{{
                $t(`menu.label.${item.id}`)
              }}</span></template
            >
          </b-menu-item>

          <!-- classes -->
          <b-menu-item
            v-for="clazz in company.classes"
            :key="clazz.name"
            class="class-name-menu-item"
            tag="a"
            :expanded="$route.query.class_name === clazz.name"
            :active="$route.query.class_name === clazz.name"
          >
            <template slot="label" slot-scope="props">
              <b-icon
                :icon="$config.icons.menu._class"
                size="is-small"
                type=""
              ></b-icon>
              <b-tooltip
                :label="clazz.label"
                position="is-top"
                :delay="600"
                class="tooltip-fix-overflow"
              >
                <span class="c-txt-ml">{{ clazz.label }}</span>
                <!-- {{ clazz.name }} -->
              </b-tooltip>
              <!-- <span class="cut-text has-text-white" > {{ clazz.name }}</span> -->
              <b-icon
                class=""
                :icon="props.expanded ? 'menu-down' : 'menu-up'"
              ></b-icon>
            </template>
            <!-- <b-menu-item
              v-for="item in clazz.menuItems"
              :key="item.id"
              tag="a"
              :class="{
                'is-active':
                  $route.path ===
                    encodeURI(`${rootPath}/${company.name}/${item.id}`) &&
                  $route.query.class_name === clazz.name,
              }"
              @click="
                routeClick({
                  path: `${rootPath}/${company.name}/${item.id}`,
                  query: { class: clazz.id, class_name: clazz.name },
                })
              "
            > -->
            <b-menu-item
              v-for="item in clazz.menuItems"
              :key="item.id"
              tag="router-link"
              :to="{
                path: `${rootPath}/${company.name}/${item.id}`,
                query: { class: clazz.id, class_name: clazz.name },
              }"
              exact
            >
              <template slot="label">
                <b-icon
                  :icon="
                    $config.icons.menu[item.id]
                      ? $config.icons.menu[item.id]
                      : $config.icons.menu._default
                  "
                  size="is-small"
                  type=""
                ></b-icon>
                <span class="c-txt-ml">{{ $t(`menu.label.${item.id}`) }}</span>
              </template>
            </b-menu-item>
          </b-menu-item>
        </b-menu-item>
      </b-menu-list>
    </b-menu>
    <b-button
      @click="logout"
      type="is-primary is-inverted"
      icon-left="logout"
      expanded
      class="align-bottom"
      >Logout</b-button
    >
  </div>
</template>

<script>
// import { authService } from "@/services";
import { resetState } from "@/store";
export default {
  name: "Menu",
  data() {
    return {
      search: "",
      rootPath: "",
      menuWidth: 30,
    };
  },
  props: {
    menuItems: {
      type: Array,
      required: false,
      validator: function (arr) {
        if (arr.length > 0)
          for (let a of arr) {
            if (!a.id) return false;
          }
        return true;
      },
      default: () => [],
    },
    classes: {
      type: Array,
      required: false,
      default: () => [],
    },
    userSchemas: {
      type: Array,
      required: false,
      default: () => [],
    },
    companies: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  created() {
    this.rootPath = this.$route.matched[0].path;
  },
  computed: {
    filteredClasses: function () {
      let filtered = [];
      for (let company of this.companies) {
        const comp = {
          name: company.name,
          label: company.label,
          menuItems: [...company.menuItems],
        };
        comp.classes = company.classes.filter((clazz) => {
          return clazz.name.toLowerCase().match(this.search.toLowerCase());
        });
        filtered.push(comp);
      }
      return filtered;
    },
  },
  methods: {
    logout: function () {
      // authService.logout();
      resetState();
      this.$router.push({ name: "Login" });
    },
    // Used beacuse buefy menu has issues with nested sub menu (it does not deselect the dropdown when selected a sibling router-link, so we need to use a annd this method)
    routeClick: function (to) {
      const toRoute = this.$router.resolve(to).route;
      if (toRoute.fullPath !== this.$route.fullPath) {
        this.$router.push(to);
      }
      this.$emit("route-click", to);
    },
  },
};
</script>

<style lang="scss" scoped>
.root-menu {
  height: 100%;
}
.is-flex-column {
  display: flex;
  flex-direction: column;
}
.align-bottom {
  margin-top: auto;
}
.cut-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.c-txt-ml {
  margin-left: 0.25rem;
}
</style>
<style lang="scss">
.class-name-menu-item {
  & > a {
    display: flex;
    align-items: center;
  }
}
.tooltip-fix-overflow {
  min-width: 0;
  flex-grow: 1;
  & .tooltip-trigger {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
