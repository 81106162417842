<template>
  <div class="iframe-wrapper" ref="iframeWrapper">
    <highlightjs v-if="isXml" language="xml" :code="xmlData" />

    <iframe
      v-else-if="mMimeType === 'text/html'"
      :src="objectData"
      ref="iframe"
      class="responsive-iframe"
      style="min-height: 96vh"
      @load="fixHeight"
      sandbox
    ></iframe>
    <object
      v-else
      :data="url"
      ref="iframe"
      class="object"
      style="min-height: 96vh"
      @load="fixHeight"
    ></object>
  </div>
</template>

<script>
export default {
  props: {
    /** Can be both an url or a base64 or blobURL string in case of a file */
    url: {
      type: String,
      required: true,
    },
    mimeType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      xmlData: "",
      objectData: null,
      mMimeType: this.mimeType,
    };
  },
  computed: {
    isXml() {
      if (this.mMimeType) {
        if (
          this.mMimeType === "application/xml" ||
          this.mMimeType === "text/xml"
        ) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    fixHeight() {
      const iframeRef = this.$refs.iframe;
      iframeRef.onload = () => {
        iframeRef.style.height =
          iframeRef.contentWindow.document.body.scrollHeight + "px";
      };
    },
    async fetchData(url) {
      const resp = await fetch(url);
      console.log(resp);
      const contentType = resp.headers.get("content-type");
      console.log("contentType", contentType);
      this.mMimeType = contentType;
      switch (contentType) {
        case "application/xml":
        case "text/xml": {
          this.xmlData = await resp.text();
          break;
        }
        case "application/pdf": {
          const data = await resp.blob();
          this.objectData = URL.createObjectURL(data);
          break;
        }
        default: {
          let data = await resp.blob();
          this.objectData = URL.createObjectURL(data);
        }
      }
    },
  },
  mounted() {
    if (this.isXml) {
      this.fetchData(this.url);
    } else {
      this.objectData = this.url;
    }
    // const iframeRef = this.$refs.iframe;
    // iframeRef.onload = () => {
    //   iframeRef.style.height =
    //     iframeRef.contentWindow.document.body.scrollHeight + "px";
    // };
  },
};
</script>

<style lang="scss">
.iframe-wrapper {
  position: relative;
  width: 100%;
  & .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    //   height: 100%;
    // min-height: 96vh;
  }
}
.object {
  width: 100%;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-break: break-word;
}
</style>
