<template>
  <section class="dashboard is-fullpageheight">
    <b-loading
      :is-full-page="true"
      v-model="menuLoading"
      :can-cancel="false"
    ></b-loading>
    <!-- <b-sidebar
      :fullheight="sidebar.fullheight"
      :reduce="sidebar.reduce"
      :position="'fixed'"
      :right="sidebar.isRight"
      :open="sidebar.open"
      :mobile="sidebar.mobile"
      :expand-on-hover="sidebar.expandOnHover"
      type="is-eurotext-menu"
    >
      <aside class="p-1">
        <Menu
          :menuItems="menuData.menuItems"
          :classes="menuData.classes"
          :userSchemas="userSchemas"
          :companies="menuData.companies"
        ></Menu>
      </aside>
    </b-sidebar> -->
    <div
      class="columns layout is-mobile no-margin"
      @mousemove.capture="resizeMove"
      @mouseup.capture="endResize"
    >
      <div
        class="column is-narrow no-padding is-sidebar"
        :class="{ 'is-collapsed': !sidebar.open }"
        :style="{ width: menuWidth + 'px' }"
      >
        <div
          class="collapse-button"
          style="align-self: flex-end; padding: 5px"
          @click="sidebar.open = !sidebar.open"
        >
          <b-icon
            type="is-white"
            :icon="sidebar.open ? 'arrow-expand-left' : 'arrow-expand-right'"
          ></b-icon>
        </div>
        <aside
          class="aside-menu p-1"
          :class="{ 'is-collapsed': !sidebar.open }"
        >
          <Menu
            :menuItems="menuData.menuItems"
            :classes="menuData.classes"
            :userSchemas="userSchemas"
            :companies="menuData.companies"
            @route-click="onMenuRouteClick"
          >
            <!-- <template #beforeCompany>
              <b-slider
                v-model="menuWidth"
                lazy
                type="is-success"
                class="is-hidden-touch"
                :tooltip="true"
                rounded
                :min="5"
                :max="90"
                :custom-formatter="(val) => $t('menu.slider')"
                @change="sliderChange"
              ></b-slider>
              <hr class="hr is-success is-hidden-desktop" />
            </template> -->
          </Menu>
        </aside>
      </div>

      <div id="resizer" class="resizer" @mousedown="initResize"></div>

      <div id="mainCol" class="is-main is-relative column no-padding">
        <tab-manager>
          <!-- v-if="menuData && menuData.companies && menuData.companies.length > 0" -->
          <router-view :key="$route.fullPath"></router-view>
        </tab-manager>
      </div>
    </div>

    <!-- <div class="hero is-fullheight is-danger is-bold">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title">
            Choose a menu item on the left
          </h1>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Menu from "@/components/Menu.vue";
import TabManager from "@/components/TabManager.vue";

import _ from "lodash";

export default {
  name: "Dashboard",
  data() {
    return {
      sidebar: {
        open: true,
        fullheight: true,
        reduce: false,
        isRight: false,
        mobile: "reduce",
        expandOnHover: true,
      },
      menuWidth: 285,
      resizing: false,
    };
  },
  components: {
    Menu,
    TabManager,
  },
  created() {
    this.fetchMenu();
    let menuWidthInitial = localStorage.getItem("menu_width") || 285;
    this.menuWidth = parseInt(menuWidthInitial);
  },
  computed: {
    ...mapState({
      menuError: (state) => state.menu.isError,
      menuLoading: (state) => state.menu.isLoading,
      menuData: (state) => state.menu.data,
      userSchemas: (state) => state.users.userSchemas,
    }),
    classObject: function () {
      return {
        "has-aside-fixed-right": this.sidebar.open && this.sidebar.isRight,
        "has-aside-fixed-left": this.sidebar.open && !this.sidebar.isRight,
      };
    },
  },
  watch: {},
  methods: {
    ...mapActions("menu", ["fetchMenu"]),
    onMenuRouteClick() {
      this.$store.commit("tabs/changeCurrentTab", 0);
      this.sidebar.open = false;
    },
    sliderChange(value) {
      localStorage.setItem("menu_width", value);
    },
    initResize() {
      this.resizing = true;
    },
    resizeMove(e) {
      if (this.resizing) {
        e.stopPropagation();
        e.preventDefault();
        this.changeSize(e);
      }
    },
    changeSize: _.debounce(function (e) {
      this.menuWidth = e.x;
    }, 100),
    // changeSize(e) {
    //   if (this.resizing) {
    //     e.preventDefault();
    //     console.log("resizing");
    //     this.menuWidth = e.x;
    //   }
    // },
    endResize(event) {
      if (this.resizing) {
        this.resizing = false;
        this.menuWidth = event.x;
        localStorage.setItem("menu_width", this.menuWidth);
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.commit("tabs/changeCurrentTab", 0);
    next();
  },
};
</script>

<style lang="scss" scoped>
aside {
  height: inherit;
}
.p-1 {
  padding: 1em;
  // padding-right: 1.4em;
}
</style>
