<template>
  <section class="box has-background-eurotext-light">
    <div class="block">
      <div class="field has-addons">
        <p class="control">
          <button class="button" @click="page > 1 ? page-- : 1">
            <span>{{ $t("policies.template.buttons.page") }} -</span>
          </button>
        </p>
        <p class="control">
          <button class="button" @click="page < totalPages ? page++ : 1">
            <span>{{ $t("policies.template.buttons.page") }} +</span>
          </button>
        </p>
        <p class="control">
          <button class="button is-static">
            <span>{{ page }} / {{ totalPages ? totalPages : "∞" }}</span>
          </button>
        </p>
      </div>

      <b-notification
        v-model="pageWarning"
        :type="notificationType"
        has-icon
        aria-close-label="Close notification"
        role="alert"
        :closable="false"
      >
        {{ $t("policies.template.compare.page_warning") }}
      </b-notification>
    </div>

    <div class="block columns" ref="anchestor">
      <div
        class="column is-half"
        v-for="(f, index) in filesData"
        :key="`${f.name}_${index}`"
      >
        <p class="title has-text-centered has-text-light is-5">{{ f.name }}</p>
        <hr />
        <div class="box has-background-light">
          <PDFViewer
            v-show="page <= f.pages"
            :src="f.data"
            :metadata="metadata"
            :viewOnly="true"
            :scale="scale"
            :page="Math.min(page, f.pages)"
            @totalPages="setFileTotalPages($event, index)"
            ref="pdf"
          ></PDFViewer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PDFViewer from "../pdfViewer/PDFViewer.vue";

function readAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = function () {
      const res = { name: file.name, data: fr.result, pages: 1 };
      console.log(res);
      resolve(res);
    };
    fr.readAsArrayBuffer(file);
  });
}

export default {
  components: { PDFViewer },
  props: {
    files: {
      type: Array,
      required: true,
    },
    metadata: {
      type: Array,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      filesData: [],
      scale: "page-width",
      page: 1,
      // totalPages: 0,
      resizeObserver: null,
      pageWarning: false,
      notificationType: "is-warning",
    };
  },
  computed: {
    totalPages() {
      let res = 0;
      const pageArr = this.filesData.map((f) => f.pages);
      return Math.max(res, ...pageArr);
    },
  },
  methods: {
    async loadFiles() {
      this.loading = true;
      try {
        this.loading = true;
        const res = await Promise.all(this.files.map(readAsArrayBuffer));
        console.log("PROMISE ALL", res);

        // res.forEach((o) => this.$set(this.filesData, o.name, o.data));
        this.filesData.push(...res);
      } catch (e) {
        console.error(e);
        this.$buefy.toast.open({
          message: this.$t("error.generic"),
          type: "is-danger",
          duration: 4000,
        });
      } finally {
        this.loading = false;
      }
    },
    setFileTotalPages(totalPages, fileIndex) {
      console.log("setting pages");
      this.filesData[fileIndex].pages = totalPages;
      // this.$set(this.filesData[fileIndex], "pages", totalPages);

      const { filesData } = this;

      if (filesData.length > 0) {
        let tempNum = filesData[0].pages;
        for (let i = 1; i < filesData.length; i++) {
          // We assume filesData[0] is the original
          if (filesData[i].pages > tempNum) {
            this.pageWarning = true;
            this.notificationType = "is-warning";
            return;
          } else if (filesData[i].pages < tempNum) {
            this.pageWarning = true;
            this.notificationType = "is-danger";
            return;
          } else {
            this.pageWarning = false;
          }
          // if (filesData[i].pages !== tempNum) {
          //   this.pageWarning = true;
          //   return;
          // } else {
          //   this.pageWarning = false;
          // }
        }
      }
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentBoxSize) {
          // Firefox implements `contentBoxSize` as a single content rect, rather than an array
          const contentBoxSize = Array.isArray(entry.contentBoxSize)
            ? entry.contentBoxSize[0]
            : entry.contentBoxSize;
          console.log(contentBoxSize);
          // this.scale = "auto";
        }
      }
    });
    this.resizeObserver.observe(this.$refs.anchestor);
  },
  created() {
    this.loadFiles();
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.$refs.anchestor);
  },
};
</script>

<style>
.scrollbar {
  overflow: auto;
}
</style>
