<template>
  <div class="container modal-card" style="width: auto">
    <section class="modal-card-body">
      <div class="columns is-centered">
        <div class="column is-narrow">
          <table class="table">
            <thead>
              <tr class="is-danger">
                <th>
                  {{ $t("code") }}
                </th>
                <th>{{ $t("description") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(error, index) in errors" :key="index">
                <td>
                  {{ error.code }}
                </td>
                <td>
                  {{ error.description }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
