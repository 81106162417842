<template>
  <div>
    <div class="toolbarContainer">
      <toolbar
        v-if="!viewOnly"
        :color-list="colorList"
        :color.sync="selectedColor"
        :tool.sync="selectedTool"
        :signer.sync="selectedSigner"
        :signerList="cSignerList"
        saveButton
        @save="save"
      ></toolbar>
    </div>
    <div ref="stageContainer" tabindex="0" @keydown.delete="deleteShape">
      <v-stage
        :config="{
          width: width * scale,
          height: height * scale,
          scaleX: scale,
          scaleY: scale,
          listening: !viewOnly,
        }"
        ref="stage"
        @mousedown="onMouseDown"
        @mousemove="onMouseMove"
        @mouseup="onMouseUp"
      >
        <v-layer ref="layer">
          <!-- <v-rect
            :config="{
              x: 0,
              y: 0,
              width: configKonva.width,
              height: configKonva.height,
              fill: 'gold',
              opacity: 0.5,
            }"
          ></v-rect> -->
          <v-rect ref="templateRect" :config="templateRectangle"></v-rect>

          <v-group
            v-for="(rect, index) in currPageSigList"
            :key="`rect_${index}`"
            :config="{
              id: `g${rect.id}`,
              name: rect.name,
            }"
            @dragend="adjustTransformation"
          >
            <v-rect
              :config="rect"
              @transformend="adjustTransformation"
            ></v-rect>

            <v-text
              :ref="`text${index}`"
              :config="{
                text: rect.signature,
                fontSize: sigTextList[index],
                x: rect.x,
                y: rect.y,
                height: rect.height * rect.scaleY,
                width: rect.width * rect.scaleX,
                verticalAlign: 'middle',
                align: 'center',
                listening: false,
              }"
            ></v-text>
          </v-group>

          <v-group
            v-for="(payday, index) in currPagePaydayList"
            :key="`payday_${index}`"
            :config="{
              id: `g${payday.id}`,
              name: payday.name,
            }"
            @dragend="adjustTransformation"
          >
            <v-rect
              :config="payday"
              @transformend="adjustTransformation"
            ></v-rect>

            <v-text
              :ref="`text${index}`"
              :config="{
                text: payday.signature,
                fontSize: sigTextList[index],
                x: payday.x,
                y: payday.y,
                height: payday.height * payday.scaleY,
                width: payday.width * payday.scaleX,
                verticalAlign: 'middle',
                align: 'center',
                listening: false,
              }"
            ></v-text>
          </v-group>

          <!-- <v-label
            v-for="(rect, index) in currPageSigList"
            :key="`rect_${index}`"
            :config="{
              id: `g${rect.id}`,
              name: rect.name,
              x: rect.x,
              y: rect.y,
              width: rect.width,
              height: rect.height,
              scaleX: rect.scaleX,
              scaleY: rect.scaleY,
            }"
            @dragend="adjustTransformation"
          >
            <v-tag :config="rect" @transformend="adjustTransformation"></v-tag>

            <v-text
              :ref="`text${index}`"
              :config="{
                text: rect.signature,
                x: rect.x,
                y: rect.y,
                height: rect.height * rect.scaleY,
                width: rect.width * rect.scaleX,
                verticalAlign: 'middle',
                align: 'center',
                listening: false,
              }"
            ></v-text>
          </v-label> -->

          <v-path
            v-for="(box, index) in currPageCheckboxList"
            :key="`checkbox_${index}`"
            :config="box"
            @transformend="adjustTransformation"
            @dragend="adjustTransformation"
          >
          </v-path>

          <!-- <v-group
            v-for="(box, index) in currPageCheckboxList"
            :key="`checkbox_${index}`"
          >
            <v-path :config="box.mark"></v-path>
            <v-rect :config="box.square"></v-rect>
          </v-group> -->
          <v-transformer ref="transformer" :config="transformerConfig" />
        </v-layer>
      </v-stage>
    </div>
  </div>
</template>

<script>
import Toolbar from "./Toolbar.vue";
import Konva from "konva";
function initialTemplateRectangle() {
  return {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    stroke: "gray",
    dash: [4, 2],
  };
}

function checkboxShape(ctx, shape) {
  ctx.beginPath();
  ctx.rect(0, 0, shape.getAttr("width"), shape.getAttr("height"));

  ctx.fillStrokeShape(shape);

  // draw cross (X)
  ctx.beginPath();
  // ctx.moveTo(shape.x(), shape.y());
  // ctx.lineTo(shape.x() + shape.width(), shape.y() + shape.height());
  // ctx.moveTo(shape.x(), shape.y() + shape.height());
  // ctx.lineTo(shape.x() + shape.width(), shape.y());
  ctx.moveTo(0, 0);
  ctx.lineTo(shape.getAttr("width"), shape.getAttr("height"));
  ctx.moveTo(0, shape.getAttr("height"));
  ctx.lineTo(shape.getAttr("width"), 0);

  ctx.fillStrokeShape(shape);

  shape.getSelfRect = function () {
    return {
      x: 0,
      y: 0,
      width: shape.width(),
      height: shape.height(),
    };
  };
}

export default {
  components: {
    Toolbar,
  },
  props: {
    viewOnly: {
      default: false,
    },
    scale: {
      default: 1,
    },
    width: {
      required: true,
    },
    height: {
      required: true,
    },
    page: {
      default: 1,
    },
    colorList: {
      default: () => ["blue", "orange", "green"],
    },
    signerList: {
      default: () => [
        {
          id: 1,
          name: "Firmatario_1",
          description: "Firmatario 1",
          color: "blue",
        },
        {
          id: 2,
          name: "Firmatario_2",
          description: "Firmatario 2",
          color: "green",
        },
        {
          id: 3,
          name: "Firmatario_3",
          description: "Firmatario 3",
          color: "pink",
        },
      ],
    },
    signer: {
      default: null,
    },
    metadata: {
      default: null,
    },
  },
  data() {
    return {
      mode: "",
      posStart: null,
      posNow: null,
      rectList: [],
      pageSignatures: {},
      pageCheckboxes: {},
      pagePaydays: {},
      configKonva: {
        width: this.width,
        height: this.height,
        scaleX: this.scale,
        scaleY: this.scale,
      },
      configCircle: {
        x: 100,
        y: 100,
        radius: 70,
        fill: "red",
        stroke: "black",
        strokeWidth: 4,
        draggable: true,
      },
      transformerConfig: {
        resizeEnabled: true,
        rotateEnabled: false,
        borderEnabled: true,
        borderStroke: "purple",
        borderStrokeWidth: 2,
        borderDash: [12, 8],
      },
      // textConfig: {
      //   x: spot.x,
      //   y: spot.y,
      //   height: spot.height,
      //   width: spot.width,
      //   verticalAlign: "middle",
      //   align: "center",
      //   scaleX: spot.scaleX,
      //   scaleY: spot.scaleY,
      // },
      templateRectangle: { ...initialTemplateRectangle() },
      selectedColor: Konva.Util.getRandomColor(),
      selectedSigner: null,
      selectedTool: "sig",
      selectedShapeId: "",
    };
  },
  watch: {
    metadata() {
      this.importFromMetadata();
    },
    // pageSignatures: {
    //   deep: true,
    //   handler() {
    //     this.updateMetadata();
    //   },
    // },
    // pageCheckboxes: {
    //   deep: true,
    //   handler() {
    //     this.updateMetadata();
    //   },
    // },
  },
  computed: {
    currPageObjList() {
      const sigList = this.pageSignatures[this.page];
      const checkList = this.pageCheckboxes[this.page];
      const paydayList = this.pagePaydays[this.page];
      return (sigList || []).concat(checkList || []).concat(paydayList || []);
    },
    currPageSigList() {
      const objList = this.pageSignatures[this.page];
      return objList ? objList : [];
    },
    currPageCheckboxList() {
      const objList = this.pageCheckboxes[this.page];
      return objList ? objList : [];
    },
    currPagePaydayList() {
      const objList = this.pagePaydays[this.page];
      return objList ? objList : [];
    },
    cSignerList() {
      if (this.signerList && this.colorList) {
        const arr = [];
        for (let i = 0; i < this.signerList.length; i++) {
          let signer = { ...this.signerList[i], color: this.colorList[i] };
          arr.push(signer);
        }
        return arr;
      } else {
        return [];
      }
    },
    sigTextList() {
      return this.currPageSigList.map((o) => {
        let rectW = o.width * o.scaleX;
        let rectH = o.height * o.scaleY;
        var t = new Konva.Text({ width: rectW, text: o.signature });
        console.log(o);

        // let size = t.measureSize(o.signature);

        // console.log("size", size);

        // if (size.width <= rectW) {
        //   return t.fontSize();
        // }

        var words = o.signature.trim().split(" ");
        let longestWord = words[0];
        let maxWordWidth = t.measureSize(longestWord).width;
        for (let i = 1; i < words.length; i++) {
          let w = t.measureSize(words[i]).width;
          if (w > maxWordWidth) {
            maxWordWidth = w;
            longestWord = words[i];
          }
        }

        // console.log(longestWord);

        while (t.fontSize() >= 8) {
          let size = t.measureSize(o.signature);

          // console.log("size", size);

          if (size.width <= rectW) {
            return t.fontSize();
          }

          // let maxWordWidth = t.measureSize(words[0]).width;
          // for (let i = 1; i < words.length; i++) {
          //   let w = t.measureSize(words[i]).width;
          //   if (w > maxWordWidth) {
          //     maxWordWidth = w;
          //   }
          // }

          maxWordWidth = t.measureSize(longestWord).width;
          console.log("maxWordWidth", maxWordWidth);
          console.log("rectW", rectW);
          console.log("rectH", rectH);

          if (maxWordWidth <= rectW) {
            if (t.height() <= rectH) {
              return t.fontSize();
            }
            // for (let i = 2; i <= words.length; i++) {
            //   // i is the possible line count from 2 to max 1 word per line
            //   let maxH = i * t.fontSize() * t.lineHeight();
            //   console.log("maxH", maxH);
            //   console.log("i", i);
            //   if (maxH <= rectH) {
            //     // let as = o.signature.split(words[words.length - 1])
            //     // let str1 = as[0].trim();
            //     // let str1 = as[1].trim();
            //     // t.measureSize(str).width

            //     // for (let i = 0; i < words.length; i++) {
            //     //   let w = t.measureSize(words[i]).width
            //     // }

            //     return t.fontSize();
            //   }
            // }
          }

          t.fontSize(t.fontSize() - 1);
          // size = t.measureSize(o.signature);

          // if (size.width > o.width * o.scaleX) {
          //   t.fontSize(t.fontSize() - 1);
          // } else {
          //   stop = true;
          // }
        }
        return t.fontSize();
      });
    },
  },
  methods: {
    startDrag(pos) {
      this.posStart = pos;
      this.posNow = pos;
    },
    updateDrag(pos) {
      this.posNow = pos;
      const posRect = this.calcRectCoordinates(this.posStart, this.posNow);
      this.templateRectangle.x = posRect.x1;
      this.templateRectangle.y = posRect.y1;
      this.templateRectangle.width = posRect.x2 - posRect.x1;
      if (this.selectedTool === "checkbox") {
        this.templateRectangle.height = posRect.x2 - posRect.x1;
      } else {
        this.templateRectangle.height = posRect.y2 - posRect.y1;
      }
      this.templateRectangle.visible = true;
    },
    calcRectCoordinates(r1, r2) {
      var r1x = r1.x,
        r1y = r1.y,
        r2x = r2.x,
        r2y = r2.y,
        d;
      if (r1x > r2x) {
        d = Math.abs(r1x - r2x);
        r1x = r2x;
        r2x = r1x + d;
      }
      if (r1y > r2y) {
        d = Math.abs(r1y - r2y);
        r1y = r2y;
        r2y = r1y + d;
      }
      return { x1: r1x, y1: r1y, x2: r2x, y2: r2y }; // return the corrected rect
    },
    drawCheckbox() {
      const { templateRectangle } = this;
      if (
        templateRectangle.x &&
        templateRectangle.y &&
        templateRectangle.width &&
        templateRectangle.height
      ) {
        const checkbox = {
          x: templateRectangle.x,
          y: templateRectangle.y,
          width: templateRectangle.width,
          height: templateRectangle.height,
          stroke: "black",
          strokeWidth: 1,
          name: "checkbox",
          id: Math.random().toString(36).substr(2, 9),
          scaleX: 1,
          scaleY: 1,
          sceneFunc: checkboxShape,
          draggable: true,
        };

        const pageArray = this.pageCheckboxes[this.page];
        if (this.pageCheckboxes[this.page]) {
          pageArray.push(checkbox);
        } else {
          this.$set(this.pageCheckboxes, this.page, new Array(checkbox));
        }
        this.resetTemplateRectangle();

        this.updateMetadata();
      }
    },
    drawRectangle() {
      const { templateRectangle } = this;
      const currSigner = this.selectedSigner;
      if (
        templateRectangle.x &&
        templateRectangle.y &&
        templateRectangle.width &&
        templateRectangle.height
      ) {
        const newRect = {
          x: templateRectangle.x,
          y: templateRectangle.y,
          width: templateRectangle.width,
          height: templateRectangle.height,
          stroke: currSigner.color,
          strokeWidth: 0,
          fill: currSigner.color,
          opacity: 0.7,
          // listening: false,
          id: `${currSigner.name}_${Math.random().toString(36).substr(2, 9)}`,
          name: "signature",
          draggable: true,
          scaleX: 1,
          scaleY: 1,
          signer: currSigner.name,
          signature: this.$t("policies.template.canvas.sig_placeholder", {
            role: this.$t(`policies.roles.${currSigner.name}`),
          }),
          // signature: `clic per firma ${this.selectedSigner.description}`,
          // signature: this.selectedSigner.description,
        };
        // this.rectList.push(newRect);
        this.addSigSlotToPage(newRect);
        this.resetTemplateRectangle();

        this.updateMetadata();
      }
    },
    drawPayday() {
      const { templateRectangle } = this;
      if (
        templateRectangle.x &&
        templateRectangle.y &&
        templateRectangle.width &&
        templateRectangle.height
      ) {
        const newPayday = {
          x: templateRectangle.x,
          y: templateRectangle.y,
          width: templateRectangle.width,
          height: templateRectangle.height,
          stroke: "gray",
          strokeWidth: 0,
          fill: "gray",
          opacity: 0.5,
          // listening: false,
          id: `payday_${Math.random().toString(36).substr(2, 9)}`,
          name: "payday",
          draggable: true,
          scaleX: 1,
          scaleY: 1,
          signature: "payday",
        };

        const pageArray = this.pagePaydays[this.page];
        if (pageArray) {
          pageArray.push(newPayday);
        } else {
          this.$set(this.pagePaydays, this.page, new Array(newPayday));
        }
        this.resetTemplateRectangle();

        this.updateMetadata();
      }
    },
    // this function will return pointer position relative to the passed node
    getRelativePointerPosition(node) {
      const transform = node.getAbsoluteTransform().copy();
      // to detect relative position we need to invert transform
      transform.invert();

      // get pointer (say mouse or touch) position
      const pos = node.getStage().getPointerPosition();

      // now we can find relative point
      return transform.point(pos);
    },
    resetTemplateRectangle() {
      this.templateRectangle = { ...initialTemplateRectangle() };
    },
    onMouseDown(e) {
      if (!this.viewOnly) {
        switch (this.selectedTool) {
          case "checkbox":
          case "payday":
          case "sig": {
            if (e.target !== e.target.getStage()) {
              const onRect = e.target.getParent().name() === "signature";
              if (onRect) return;
            }

            const mousePos = this.getRelativePointerPosition(
              this.$refs.layer.getNode()
            );
            // const mousePos = this.$refs.stage.getNode().getPointerPosition();
            this.mode = "drawing";
            this.startDrag(mousePos);
            break;
          }
          case "select": {
            console.log(e.target);
            // clicked on stage - clear selection
            if (e.target === e.target.getStage()) {
              this.selectedShapeId = "";
              this.updateTransformer();
              return;
            }

            // clicked on transformer - do nothing
            const clickedOnTransformer =
              e.target.getParent().className === "Transformer";
            if (clickedOnTransformer) {
              return;
            }

            // find clicked rect by its id
            const id = e.target.id();
            const rect = this.currPageObjList.find((r) => r.id === id);
            if (rect) {
              this.selectedShapeId = id;
            } else {
              this.selectedShapeId = "";
            }
            this.updateTransformer();
            break;
          }
        }
      }
    },
    onMouseMove() {
      if (this.mode === "drawing") {
        const mousePos = this.getRelativePointerPosition(
          this.$refs.layer.getNode()
        );
        this.updateDrag(mousePos);
      }
    },
    onMouseUp() {
      this.mode = "";
      switch (this.selectedTool) {
        case "checkbox": {
          this.drawCheckbox();
          break;
        }
        case "sig": {
          this.drawRectangle();
          break;
        }
        case "payday": {
          this.drawPayday();
          break;
        }
      }
    },
    addSigSlotToPage(obj) {
      this.addSigSlotToCurrentPage(this.page, obj);
    },
    addSigSlotToCurrentPage(pageNum, obj) {
      const pageSignatures = this.pageSignatures[pageNum];
      if (pageSignatures) {
        pageSignatures.push(obj);
      } else {
        this.$set(this.pageSignatures, pageNum, new Array(obj));
      }
    },
    updateTransformer() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();

      // // do nothing if selected node is already attached
      // if (selectedNode === transformerNode.node()) {
      //   return;
      // }
      if (this.selectedShapeId) {
        const selectedNode = stage.findOne("#" + this.selectedShapeId);
        console.log(selectedNode.name());
        selectedNode.name() === "checkbox"
          ? transformerNode.keepRatio(true)
          : transformerNode.keepRatio(false);
        console.log(transformerNode.keepRatio());
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
      transformerNode.getLayer().batchDraw();
    },
    deleteShape() {
      const transformer = this.$refs.transformer.getNode();
      const nodes = transformer.nodes();
      if (nodes.length > 0) {
        for (let node of nodes) {
          let arr = [];
          const nodeName = node.name();
          if (nodeName === "signature") {
            arr = this.currPageSigList;
          } else if (nodeName === "payday") {
            arr = this.currPagePaydayList;
          } else if (nodeName === "checkbox") {
            arr = this.currPageCheckboxList;
          }
          const index = arr.findIndex((obj) => {
            return obj.id === node.id();
          });
          if (index >= 0) {
            arr.splice(index, 1);
          }
        }
      }
      transformer.nodes([]);
      transformer.getLayer().batchDraw();
    },
    importFromMetadata() {
      const metaCheck = {};
      const metaPay = {};
      const metaSig = {};
      if (this.metadata) {
        // console.log("meta", this.metadata);
        const m = JSON.parse(JSON.stringify(this.metadata));
        console.log("m", m);
        m.forEach((o) => {
          metaCheck[o.page] = o.checkboxSlots.map((c) => {
            return { ...c, sceneFunc: checkboxShape };
          });

          metaPay[o.page] = o.paydaySlots
            ? o.paydaySlots.map((pd) => {
                return { ...pd };
              })
            : [];

          metaSig[o.page] = o.signatureSlots.map((ss) => {
            let role = this.signerList.find((s) => s.name === ss.signer);
            let roleName = role ? role.name : ss.signer;
            return {
              ...ss,
              signature: this.$t("policies.template.canvas.sig_placeholder", {
                role: this.$t(`policies.roles.${roleName}`),
              }),
            };
          });
        });
      }
      this.pageSignatures = metaSig;
      this.pageCheckboxes = metaCheck;
      this.pagePaydays = metaPay;
    },
    formatMetadata() {
      const formattedData = Object.keys(this.pageSignatures).map((key) => {
        return {
          page: key,
          signatureSlots: this.pageSignatures[key],
          checkboxSlots: [],
        };
      });
      // Add checkboxes
      Object.keys(this.pageCheckboxes).forEach((key) => {
        const checkboxes = this.pageCheckboxes[key];
        let found = formattedData.find((obj) => obj.page === key);
        if (found) {
          found.checkboxSlots = checkboxes;
        } else {
          formattedData.push({
            page: key,
            checkboxSlots: checkboxes,
            signatureSlots: [],
          });
        }
      });
      // Add paydays
      Object.keys(this.pagePaydays).forEach((key) => {
        const paydays = this.pagePaydays[key];
        let found = formattedData.find((obj) => obj.page === key);
        if (found) {
          found.paydaySlots = paydays;
        } else {
          formattedData.push({
            page: key,
            checkboxSlots: [],
            signatureSlots: [],
            paydaySlots: paydays,
          });
        }
      });
      return JSON.stringify(formattedData);
    },
    updateMetadata() {
      const stringData = JSON.parse(this.formatMetadata());
      this.$emit("update:metadata", stringData);
    },
    save() {
      const stringData = this.formatMetadata();
      this.$emit("save", stringData);
      console.log(stringData);
    },
    adjustTransformation(e) {
      const target = e.target;
      console.log("trasnforming", e);
      const rect = this.currPageObjList.find((obj) => obj.id === target.id());
      // rect.width = target.width() * target.scaleX();
      // rect.height = target.height() * target.scaleY();
      rect.x = target.x();
      rect.y = target.y();
      rect.scaleY = target.scaleY();
      rect.scaleX = target.scaleX();

      this.updateMetadata();
    },
  },
  mounted() {
    if (this.signer === null) {
      if (this.cSignerList && this.cSignerList.length > 0) {
        this.selectedSigner = this.cSignerList[0];
      }
    } else if (this.signer === undefined) {
      this.selectedSigner = undefined;
    } else {
      this.selectedSigner = this.cSignerList.find(
        (s) => this.signer.name === s.name
      );
    }
  },
  created() {
    this.importFromMetadata();
  },
};
</script>

<style>
.toolbarContainer {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  /* background-color: violet; */
}
</style>
