<template>
  <section class="section">
    <div class="block">
      <button
        type="button"
        class="button is-primary"
        @click="fetchVolumeDocuments"
      >
        <b-icon icon="update"></b-icon>
        <span>{{ $t("buttons.refresh") }}</span>
      </button>
    </div>
    <!-- BUTTONS -->
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <!-- Validation button -->
          <b-tooltip
            :type="validated ? 'is-success' : 'is-danger'"
            :active="showValidationPopup"
            :label="validationResultMsg"
            class="move-right"
            size="is-small"
            multilined
            always
            animated
          >
            <!-- Can be disabled adding :disabled="validated" to button -->
            <button
              class="button is-primary"
              @click="validateVolume"
              v-if="mIsSignedVolume"
            >
              <b-icon icon="text-box-check"></b-icon>
              <span>{{ $t("buttons.validate_document") }}</span>
            </button>
          </b-tooltip>
        </div>
        <div class="level-item">
          <button
            class="button is-primary"
            @click="displayVolumeDownloadDialog = true"
            :disabled="!validated"
            v-if="mIsSignedVolume"
          >
            <b-icon icon="download"></b-icon>
            <span>{{ $t("buttons.download") }}</span>
          </button>
        </div>
        <div class="level-item">
          <button
            class="button is-primary"
            @click="displaySignatureTimeDialog = true"
            :disabled="!validated || !signatureTime"
            v-if="mIsSignedVolume"
          >
            <b-icon icon="clock"></b-icon>
            <span>{{ $t("buttons.show_signature_timing") }}</span>
          </button>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item"></div>
      </div>
    </div>

    <DocumentTable
      :data="tableData"
      :tableColumns="tableColumns"
      :isLoadingRows="loadingDocuments"
      :total="totalTablePages"
      :perPage="tablePerPage"
      :totalRecords="totalDocumentNum"
      :documentType="'history'"
      :downloadTypes="downloadTypes"
      :saveState="true"
      :stateKey="stateKey"
      @row-click="onRowClick"
      @download="onDownloadDocuments"
      @download-total="onDownloadDocumentsTotal"
      @page="onTablePage($event)"
    ></DocumentTable>

    <!-- <document-detail
          :company-schema="companyName"
          :documentId="docId"
          :properties="documentProperties"
          :isHistory="true"
          :state-key="stateKey"
        ></document-detail> -->

    <!-- DOWNLOAD DIALOG -->
    <b-modal
      :active.sync="displayVolumeDownloadDialog"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <modal-type-selection
          :downloadTypes="volumeDownloadTypes"
          @download="onDownloadVolume"
        ></modal-type-selection>
      </template>
    </b-modal>

    <b-modal
      v-if="signatureTime"
      :active.sync="displaySignatureTimeDialog"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <SignatureTimeDisplay
          :timestamp="signatureTime.timestamp"
          :signatures="signatureTime.signatures"
        ></SignatureTimeDisplay>
      </template>
    </b-modal>
  </section>
</template>

<script>
import DocumentTable from "@/components/DocumentTable/DocumentTable";
import ModalTypeSelection from "@/components/ModalTypeSelection";
// import DocumentDetail from "@/components/DocumentDetail";
import SignatureTimeDisplay from "@/components/SignatureTimeDisplay";
import {
  getDocumentDownloadType,
  getVolumeDownloadType,
  KUrls,
} from "@/helpers/constants";
import {
  volumeService,
  downloadService,
  validationService,
  fetchDocumentsResponseSchema,
  errorResponseSchema,
} from "@/services";
export default {
  components: {
    DocumentTable,
    ModalTypeSelection,
    // DocumentDetail,
    SignatureTimeDisplay,
  },
  props: {
    volumeId: {
      type: Number,
    },
    companyName: {
      type: String,
      required: true,
    },
    signedVolume: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      loadingDocuments: false,
      isValidating: false,
      isDownloadingVolume: true,
      displayVolumeDownloadDialog: false,
      displaySignatureTimeDialog: false,
      volumeDownloadTypes: [...getVolumeDownloadType()],
      docIdDetailTabs: [],
      activeTab: 0,
      downloadService: false,
      tablePage: 1,
      tablePerPage: 10,
      totalTablePages: 0,
      totalDocumentNum: 0,
      documents: [],
      downloadTypes: [...getDocumentDownloadType()],
      validated: false,
      showValidationPopup: false,
      validationResultMsg: "",
      signatureTime: null,
      // signatureTime: {
      //   signatures: [
      //     { name: "Mario", surname: "Rossi", timeUTC: "2020-11-11" },
      //     { name: "Luca", surname: "Colombo", timeUTC: "2020-11-11" },
      //     { name: "Luca", surname: "Colombo", timeUTC: "2020-11-11" },
      //     { name: "Luca", surname: "Colombo", timeUTC: "2020-11-11" },
      //   ],
      //   timestamp: "2020-11-11",
      // },
    };
  },
  computed: {
    mIsSignedVolume() {
      if (this.signedVolume !== undefined) {
        return this.signedVolume;
      } else {
        return this.$route.path.includes(KUrls.routes.SIGNED_VOLUMES);
      }
    },
    documentProperties() {
      let props = [];
      if (this.documents.length > 0) {
        props = this.documents[0].properties.map((prop) => {
          return { ...prop };
        });
      }
      return props;
    },
    isLoading() {
      return this.loadingDocuments || this.isDownloading;
    },
    searchableDocuments() {
      if (this.documents) {
        const docs = [];
        for (const doc of this.documents) {
          const newDoc = { ...doc };
          newDoc.properties = doc.properties.filter(
            (prop) => prop.searchable === "Y"
          );
          docs.push(newDoc);
        }
        return docs;
      } else {
        return [];
      }
    },
    stateKey() {
      return `${this.companyName}.volume.${this.volumeId}.docs`;
    },
    tableColumns() {
      let columns = [];
      if (this.searchableDocuments.length > 0) {
        columns = this.searchableDocuments[0].properties.map((prop) => {
          const col = {
            field: prop.name,
            label: this.$t(`docclass-properties.${prop.description}`),
          };
          return col;
        });
        if (!columns.some((c) => c.field === "id")) {
          columns.push({
            field: "id",
            label: this.$t(`docclass-properties.id`),
            visible: false,
          });
        }
      }
      return columns;
    },
    tableData() {
      return this.documents.map((doc) => {
        const obj = {};
        for (const prop of doc.properties) {
          obj[prop.name] = prop.value;
        }
        return obj;
      });
    },
  },
  methods: {
    /**
     * Close a tab
     */
    closeTab: function (tabIndex) {
      if (this.activeTab === tabIndex + 1) {
        this.activeTab = 0;
      }
      this.docIdDetailTabs.splice(tabIndex, 1);
    },
    fetchVolumeDocuments() {
      this.loadingDocuments = true;
      volumeService
        .getVolumeDocuments(
          this.companyName,
          this.volumeId,
          this.tablePerPage,
          this.tablePage,
          this.mIsSignedVolume
        )
        .then((data) => {
          this.documents = data.documents;
          this.totalDocumentNum =
            data[fetchDocumentsResponseSchema.totalResults];
          this.tablePage = data[fetchDocumentsResponseSchema.page];
          this.totalTablePages = data[fetchDocumentsResponseSchema.totalPages];
        })
        .finally(() => (this.loadingDocuments = false));
    },
    onDownloadVolume(payload) {
      this.displayVolumeDownloadDialog = false;
      this.isDownloadingVolume = true;
      var artifacts = 0;
      for (const type of payload) {
        artifacts += type;
      }
      const ids = [this.volumeId];
      downloadService
        .downloadVolume(
          this.companyName,
          this.$i18n.locale,
          "ddMMyyyy",
          true,
          ids,
          artifacts
        )
        .then((data) => {
          console.log(data);
        })
        .finally(() => (this.isDownloadingVolume = false));
    },
    onDownloadDocuments(payload) {
      const artifacts = payload.artifacts;
      const documents = payload.selected;
      const ids = [];
      for (const doc of documents) {
        ids.push(doc.id);
      }
      this.isDownloading = true;
      downloadService
        .downloadDocuments(
          this.companyName,
          this.$i18n.locale,
          "ddMMyyyy",
          true, // timeStyle24H
          ids,
          artifacts,
          []
        )
        .then((data) => {
          console.log(data);
        })
        .finally(() => (this.isDownloading = false));
    },
    onDownloadDocumentsTotal(payload) {
      const artifacts = payload.artifacts;
      this.isDownloading = true;
      const filters = [];
      downloadService
        .downloadAllDocuments(
          this.companyName,
          this.className,
          filters,
          this.$i18n.locale,
          "ddMMyyyy",
          true, //timeStyle24H
          artifacts,
          [],
          true //isHistory
        )
        .then((data) => {
          console.log(data);
        })
        .finally(() => (this.isDownloading = false));
    },
    onRowClick: function (payload) {
      const docId = payload.id;
      const tab = {
        id: docId,
        component: "DocumentDetail",
        label: `Doc ${docId}`,
        icon: this.$config.icons.tabs.document_detail,
        props: {
          companySchema: this.companyName,
          documentId: docId,
          properties: [...this.documentProperties],
          stateKey: this.stateKey,
          isHistory: true,
        },
      };
      this.$store.dispatch("tabs/openTab", tab);
    },
    onTablePage: function (event) {
      this.tablePage = event.page;
      this.tablePerPage = event.rows;
      this.fetchVolumeDocuments();
    },
    showTimestamp() {},
    validateVolume() {
      this.isValidating = true;
      validationService
        .validateVolume(this.companyName, this.volumeId)
        .then((data) => {
          if (data.success) {
            this.validated = true;
            this.validationResultMsg = this.$t(
              "document.validation.success.message"
            );
            this.signatureTime = data.data;
          } else {
            const code = data[errorResponseSchema.code];
            this.validationResultMsg = this.$t(
              `document.validation.errors.${code}`
            );
          }
          this.showValidationPopup = true;
          setTimeout(() => (this.showValidationPopup = false), 4000);
        })
        .finally(() => {
          this.isValidating = false;
        });
    },
  },
  mounted() {
    this.fetchVolumeDocuments();
  },
};
</script>

<style></style>
