<template>
  <b-tabs v-model="activeTab" class="tabs-container">
    <!-- Tab page icon -->
    <b-tab-item icon="home">
      <template slot="header">
        <b-icon icon="home"></b-icon>
      </template>

      <!-- Tab page content -->
      <slot name="default"></slot>
    </b-tab-item>

    <template v-for="(tab, index) in tabs">
      <b-tab-item :key="tab.id" v-if="!tab.hidden">
        <template slot="header">
          <b-icon v-if="tab.icon" :icon="tab.icon"></b-icon>
          <span
            >{{ tab.label
            }}<span @click.stop="closeTab(index, tab.id)">
              <b-icon
                icon="close"
                type="is-danger"
                size="is-small"
              ></b-icon></span
          ></span>
        </template>
        <!-- https://codesandbox.io/s/broken-dream-l7jqj?file=/src/App.vue -->
        <component :is="tab.component" v-bind="tab.props"></component>
      </b-tab-item>
    </template>
  </b-tabs>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import DocumentDetail from "@/components/DocumentDetail.vue";
import EInvoiceDetail from "@/components/EInvoiceDetail.vue";
import VolumeDetail from "@/components/VolumeDetail.vue";
import DossierDetail from "@/components/Dossiers/DossierDetail.vue";
import IFrame from "@/components/IFrame.vue";
import GroupTemplateCreation from "@/components/policies/GroupTemplateCreation.vue";
import TemplateCompare from "@/components/policies/TemplateCompare.vue";
// import Pdf2 from "@/components/pdfReview/Pdf2.vue";
import Pdf from "@/components/policies/Pdf";
import UserDetail from "@/components/policies/users/UserDetail";
export default {
  components: {
    DocumentDetail,
    EInvoiceDetail,
    VolumeDetail,
    DossierDetail,
    IFrame,
    GroupTemplateCreation,
    Pdf,
    TemplateCompare,
    UserDetail,
  },
  data() {
    return {
      // activeTab: 0,
    };
  },
  computed: {
    // ...mapState({
    //   tabs: (state) => state.tabs.tabs,
    //   activeTab: (state) => state.tabs.currentTab,
    // }),
    // ...mapGetters("tabs", [
    //   ""
    // ])
    tabs: {
      get() {
        // console.log("getting tabs");
        return this.$store.state.tabs.tabs;
      },
      set(value) {
        this.$store.commit("tabs/removeTab", value);
      },
    },
    tabsLength() {
      return this.$store.state.tabs.tabs.length;
    },
    activeTab: {
      get() {
        // console.log("getting current tab");
        console.log(this.$store.state.tabs.currentTab);
        return this.$store.state.tabs.currentTab;
      },
      set(value) {
        // console.log("setting active tab value", value);
        this.updateCurrentTab(value);
      },
    },
  },
  watch: {
    tabsLength: {
      handler(newValue, oldValue) {
        // console.log("changing tabs value", newValue, oldValue);
        if (newValue > oldValue) {
          // this.activeTab = newValue.length;
          this.$nextTick(() => (this.activeTab = newValue));
        }
      },
      // deep: true,
    },
  },
  methods: {
    ...mapMutations({
      updateCurrentTab: "tabs/changeCurrentTab",
    }),
    ...mapActions({
      closeTab: "tabs/closeTab",
      removeTab: "tabs/removeTab",
    }),
    closeTab(tabIndex, tabId) {
      console.log(this.activeTab, tabIndex);

      // this.$nextTick(() => this.removeTab(tabId));
      this.removeTab(tabId);
      if (this.activeTab >= tabIndex) {
        // this.activeTab = this.activeTab - 1;
        this.$nextTick(() => (this.activeTab = this.activeTab - 1));
      }
    },
  },
};
</script>

<style lang="scss">
.b-tabs {
  & .tab-content {
    padding: 0;
  }
}
</style>
