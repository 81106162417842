<template>
  <div>
    <p class="has-text-centered title is-size-4">
      {{ $t(`policies.template.canvas.toolbar_header`) }}
    </p>
    <nav class="level">
      <div class="level-left">
        <!-- Color selection -->
        <div class="level-item">
          <div class="control">
            <div class="select">
              <select v-model="selectedSigner">
                <!-- <option disabled value=""></option> -->
                <option
                  v-for="(sig, index) in signerList"
                  :key="index"
                  :value="sig"
                >
                  {{ sig.description }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Tools -->
        <div class="level-item" v-for="tool in availableTools" :key="tool.id">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="tool"
                :value="tool.id"
                v-model="selectedTool"
              />
              {{ $t(`policies.template.canvas.${tool.label}`) }}
            </label>
          </div>
        </div>
      </div>

      <div class="level-right">
        <div class="level-item" v-if="saveButton">
          <button class="button is-primary" @click="save">
            {{ $t("policies.template.buttons.save") }}
          </button>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
const defaultTools = [
  { id: "sig", label: "signature" },
  { id: "checkbox", label: "checkbox" },
  { id: "payday", label: "payday" },
  { id: "select", label: "select" },
];
export default {
  props: {
    colorList: {
      default: [],
    },
    color: {
      default: null,
    },
    signerList: {
      default: () => [
        { name: "Firmatario 1", color: "blue" },
        { name: "Firmatario 2", color: "green" },
        { name: "Firmatario 3", color: "purple" },
      ],
    },
    signer: {
      default: null,
    },
    tool: {
      default: "rect",
    },
    toolList: {
      default: () => [...defaultTools],
    },
    saveButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // availableTools: ["rect", "checkbox", "select"],
      availableTools: this.toolList,
      selectedSigner: this.signer,
      selectedSignerColor: this.color,
      selectedTool: this.tool,
    };
  },
  watch: {
    signer(newVal) {
      this.selectedSigner = this.signerList.find(
        (sig) => sig.name === newVal.name
      );
    },
    selectedSignerColor(newColor) {
      this.$emit("update:color", newColor);
    },
    selectedTool(newTool) {
      this.$emit("update:tool", newTool);
    },
    selectedSigner(newSigner) {
      this.$emit("update:signer", newSigner);
      this.$emit("update:color", newSigner.color);
    },
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style></style>
