<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <b-table
        :data="templates"
        :loading="loadingTemplates"
        :selected.sync="selected"
        focusable
        paginated
        backend-pagination
        :total="totalResults"
        :per-page="perPage"
        @page-change="onPageChange"
        @dblclick="onDoubleClick"
      >
        <template v-for="(column, index) in tableColums">
          <b-table-column :key="index" v-bind="column">
            <template v-if="column.searchable" #searchable="props">
              <b-input
                v-model="filters[props.column.field]"
                :placeholder="$t('search')"
                icon="magnify"
              />
            </template>
            <template v-slot="props">
              {{ props.row[column.field] }}
            </template>
          </b-table-column>
        </template>
      </b-table>
    </section>
    <footer class="modal-card-foot h-centered">
      <button class="button" type="button" @click="$parent.close()">
        <b-icon icon="close"></b-icon>
        <span>{{ $t("buttons.close") }}</span>
      </button>
      <button
        class="button is-primary"
        type="button"
        :disabled="!Boolean(selected)"
        @click="importTemplate"
      >
        <b-icon icon="download"></b-icon>
        <span>{{ $t("buttons.import") }}</span>
      </button>
    </footer>
  </div>
</template>

<script>
import _ from "lodash";
import { policiesService } from "../../services";
export default {
  props: {
    companyName: {
      type: String,
      required: true,
    },
    merchantUniqueName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingTemplates: false,
      page: 1,
      perPage: 20,
      totalResults: 0,
      templates: [],
      selected: null,
      tableColums: [
        {
          field: "name",
          label: this.$t("policies.template.name.label"),
          centered: false,
          searchable: true,
        },
        // {
        //   field: "type",
        //   label: this.$t("policies.template.type.label"),
        //   centered: true,
        //   searchable: true,
        // },
        {
          field: "signers_nr",
          label: this.$t("policies.template.signers_nr.label"),
          // width: "100",
          centered: true,
          searchable: false,
        },
      ],
      filters: {
        name: "",
      },
    };
  },
  computed: {},
  watch: {
    filters: {
      deep: true,
      handler: function () {
        this.onFilter();
      },
    },
  },
  methods: {
    getTemplates() {
      this.loadingTemplates = true;
      policiesService
        .fetchTemplates(
          this.companyName,
          this.merchantUniqueName,
          null,
          null,
          this.page,
          this.perPage,
          this.filters
        )
        .then((res) => {
          this.templates = res["templates"];
          this.totalResults = res["items_found"];
        })
        .finally(() => (this.loadingTemplates = false));
    },
    importTemplate() {
      this.$emit("import", this.selected);
      this.$parent.close();
    },
    onDoubleClick(row) {
      this.selected = row;
      this.$emit("import", row);
      this.$parent.close();
    },
    onFilter: _.debounce(function () {
      this.getTemplates();
    }, 500),
    onPageChange(pageNr) {
      this.page = pageNr;
      this.getTemplates();
    },
  },
  mounted() {
    this.getTemplates();
  },
};
</script>

<style></style>
