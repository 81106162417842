<template>
  <section>
    <b-loading :active="pageLoading" :is-full-page="false"></b-loading>
    <b-modal v-model="modalRevokeActive" has-modal-card>
      <user-revocation
        :checkbox="true"
        type="is-danger"
        :user="username"
        @cancel="modalRevokeActive = false"
        @confirm="
          (revokeCert) => {
            revokeUser(revokeCert);
            modalRevokeActive = false;
          }
        "
      ></user-revocation>
    </b-modal>

    <section class="section has-background-eurotext-light">
      <div class="container">
        <div
          v-if="Object.keys(infos).length > 0 && !statusRevoked"
          class="mb-5"
        >
          <b-button type="is-danger" size="" @click="modalRevokeActive = true">
            {{ $t("policies.rao.revocation.buttons.revoke") }}
          </b-button>
        </div>
        <!-- <div class="box level">
          <div v-if="status" class="level-item has-text-centered">
            <div>
              <p class="heading is-size-6">
                {{ $t("policies.rao.status.label") }}
              </p>
              <p>
                <b-tag :type="`is-user-${status}`" size="is-medium">
                  <span class="has-text-weight-bold">{{
                    $t(`policies.rao.status.${status}`)
                  }}</span>
                </b-tag>
              </p>
            </div>
          </div>
        </div> -->
        <div class="field is-grouped is-grouped-multiline">
          <div v-if="status" class="field">
            <div class="label has-text-light">
              {{ $t("policies.rao.status.label") }}
            </div>
            <b-tag :type="`is-user-${status}`" size="is-large">
              <span class="has-text-weight-bold">{{
                $t(`policies.rao.status.${status}`)
              }}</span>
            </b-tag>
          </div>

          <div v-if="infos.userRegistered" class="field">
            <label class="label has-text-light">
              {{ $t("policies.rao.userRegistered.label") }}
            </label>
            <div class="has-text-centered">
              <div class="tag is-large">
                <b-icon
                  :icon="infos.userRegistered.value ? 'check' : 'close'"
                  :type="
                    infos.userRegistered.value ? 'is-success' : 'is-danger'
                  "
                  size=""
                ></b-icon>
              </div>
            </div>
          </div>

          <template v-if="certInfo && Object.keys(certInfo).length > 0">
            <b-field>
              <template #label>
                <span class="has-text-light">{{
                  $t("policies.rao.certExpiration.label")
                }}</span>
              </template>
              <template #default>
                <b-input v-model="certInfo.expirationDate" disabled></b-input>
              </template>
            </b-field>

            <b-field>
              <template #label>
                <span class="has-text-light">{{
                  $t("policies.rao.certCreation.label")
                }}</span>
              </template>
              <template #default>
                <b-input v-model="certInfo.creationDate" disabled></b-input>
              </template>
            </b-field>

            <b-button
              v-if="certInfo.certificate"
              type="is-primary"
              icon-left="download"
              @click="downloadCertificate"
              class="mb-3"
              style="align-self: flex-end"
            >
              {{ $t("buttons.download_certificate") }}
            </b-button>
          </template>
        </div>

        <ValidationObserver ref="observer">
          <user-data-mask
            v-if="infos && Object.keys(infos).length > 0"
            ref="dataMask"
            v-model="infos"
            :organizationEnabled.sync="showOrgData"
            :organizationSwitch="false"
          ></user-data-mask>

          <user-file-mask
            ref="fileMask"
            :fileTypes="fileTypes"
            :companyName="companyName"
            :infos="infos"
            :uploadButton="status === 'OK'"
            @upload-complete="searchUserDocs"
            class="mt-6"
          ></user-file-mask>
        </ValidationObserver>
      </div>

      <div class="container mt-3" v-if="statusNotApproved">
        <b-button type="is-primary" native-type="button" @click="approveUser">
          Approva utente
        </b-button>
      </div>

      <div class="container mt-6">
        <p class="label is-size-5 has-text-light">
          {{ $t("policies.rao.uploaded_user_files.label") }}
        </p>
        <div class="box mt-3">
          <b-table
            :data="userDocs"
            :columns="userDocsColumns"
            :loading="loadingDocs"
            :row-class="() => 'is-clickable'"
            hoverable
            @click="downloadDoc"
          ></b-table>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { parseISO, format } from "date-fns";
import FileSaver from "file-saver";
import { downloadService, raoServices, searchService } from "../../../services";
import UserDataMask from "./UserDataMask.vue";
import UserFileMask from "./UserFileMask.vue";
import UserRevocation from "./UserRevocation.vue";
import { b64ToFile } from "../../../helpers";
import { KDocumentDownloadType, Operators } from "../../../helpers/constants";

const notRequiredFields = [
  "organizationName",
  "organizationCode",
  "organizationCountry",
];

const notEnabledFields = [
  "organizationName",
  "organizationCode",
  "organizationCountry",
];

export default {
  components: {
    ValidationObserver,
    UserDataMask,
    UserFileMask,
    UserRevocation,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      infos: {},
      certInfo: null,
      status: null,
      loadingDetails: false,
      loadingDocs: false,
      isDownloading: false,
      revokingUser: false,
      modalRevokeActive: false,
      fileTypes: {
        identificationFile: { files: null, hidden: false, required: false },
        fiscalCodeFile: { files: null, hidden: false, required: false },
        imageFile: { files: null, hidden: false, required: false },
        oraganizationFile: { files: null, hidden: false, required: false },
        otherFiles: { files: null, hidden: false, required: false },
      },
      userDocs: [],
      userDocsColumns: [
        {
          field: "description",
          label: this.$t("docclass-properties.description"),
        },
        {
          field: "source_filename",
          label: this.$t("docclass-properties.source_filename"),
        },
        {
          field: "input_date",
          label: this.$t("docclass-properties.input_date"),
        },
        // {
        //   field: "username",
        //   label: this.$t("docclass-properties.uploaded_by"),
        // },
      ],
    };
  },
  computed: {
    pageLoading() {
      return this.loadingDetails || this.revokingUser || this.isDownloading;
    },
    showOrgData() {
      if (this.infos && Object.keys(this.infos).length > 0) {
        if (
          this.infos["organizationName"].value ||
          this.infos["organizationCode"].value ||
          this.infos["organizationCountry"].value
        ) {
          return true;
        }
      }
      return false;
    },
    statusNotApproved() {
      return this.status === "NOT_APPROVED";
    },
    statusRevoked() {
      return this.status === "DISABLED";
    },
    username() {
      return this.infos.domainUsername ? this.infos.domainUsername.value : "";
    },
  },
  watch: {
    statusNotApproved(newVal) {
      this.fileTypes.identificationFile.required = newVal;
    },
    // showOrgData(newVal) {
    //   if (!newVal) {
    //     this.fileTypes.oraganizationFile.hidden = true;
    //     this.fileTypes.oraganizationFile.files = null;
    //   } else {
    //     this.fileTypes.oraganizationFile.hidden = false;
    //   }
    // },
    showOrgData: {
      handler(newVal) {
        if (!newVal) {
          this.fileTypes.oraganizationFile.hidden = true;
          this.fileTypes.oraganizationFile.files = null;
        } else {
          this.fileTypes.oraganizationFile.hidden = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getUserDetail() {
      this.loadingDetails = true;
      try {
        const res = await raoServices.fetchUserDetails(this.userId);
        const status = res["status"];
        const newInfos = {};
        const exclude = ["certificate", "status"];
        Object.keys(res)
          .filter((key) => !exclude.includes(key))
          .forEach((key) => {
            const obj = {
              value: res[key],
              disabled:
                status !== "NOT_APPROVED" ||
                !!res[key] ||
                res[key] === false ||
                notEnabledFields.includes(key),
              required: !notRequiredFields.includes(key),
              // disabled: true,
            };
            if (key === "birthDate" && res[key]) {
              obj.value = format(parseISO(res[key]), "dd/MM/yyyy");
            }
            if (key === "recognitionType" && res[key] === "NONE") {
              obj.disabled = false;
            }
            if (key === "organizationCode") {
              const tin = "organizationTypeTIN";
              const tinObj = {
                value: res[key] && res[key].startsWith("TIN"),
                disabled: obj.disabled,
                required: !notRequiredFields.includes(tin),
              };
              newInfos[tin] = tinObj;
              this.$set(this.infos, tin, tinObj);

              obj.value = obj.value.replace(/(TIN|VAT)[A-Z]{2}-/, "");
            }
            newInfos[key] = obj;
            this.$set(this.infos, key, obj);
          });

        const certRes = res["certificate"];
        if (certRes) {
          certRes.expirationDate = certRes.expirationDate
            ? format(parseISO(certRes.expirationDate), "dd-MM-yyyy")
            : null;
          certRes.creationDate = certRes.creationDate
            ? format(parseISO(certRes.creationDate), "dd-MM-yyyy")
            : null;
        }
        this.certInfo = certRes;
        this.status = status;
        this.infos = newInfos;
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingDetails = false;
      }
    },
    downloadCertificate() {
      const b64cert = this.certInfo.certificate;
      if (b64cert) {
        const file = b64ToFile(
          b64cert,
          `${this.username}.cer`,
          "application/pkix-cert"
        );
        FileSaver.saveAs(file);
      }
    },
    confirmRevoke() {
      const username = this.infos.domainUsername
        ? this.infos.domainUsername.value
        : "";
      this.$buefy.dialog.confirm({
        message: this.$t("policies.rao.revocation.confirm.msg", {
          user: username,
        }),
        confirmText: this.$t("buttons.continue"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.revokeUser(true, true),
      });
    },
    async revokeUser(revokeCert, showSuccess = true) {
      try {
        this.revokingUser = true;
        let subNickname = this.infos.subNickname
          ? this.infos.subNickname.value
          : null;
        await raoServices.revokeUser(subNickname, revokeCert);

        if (showSuccess) {
          this.$buefy.toast.open({
            message: this.$t("policies.rao.revocation.success"),
            type: "is-success",
            position: "is-top-right",
            duration: 4000,
          });
        }

        this.getUserDetail();
      } catch (e) {
        console.log(e);
      } finally {
        this.revokingUser = false;
      }
    },
    async approveUser() {
      try {
        const { observer, dataMask, fileMask } = this.$refs;
        const valid = await observer.validate();
        if (!valid) {
          console.log("observer not valid");
          return;
        }

        await fileMask.uploadFiles();

        const formattedData = dataMask.getFormattedInfos();
        formattedData.isApproved = true;
        formattedData.domainUsername = formattedData.subNickname;
        formattedData.raoDomainUsername = this.$store.state.users.subNickname;

        await raoServices.approveUser(formattedData);

        this.$buefy.toast.open({
          message: this.$t("policies.upload.success.user_metadata"),
          type: "is-success",
          duration: 4000,
        });

        this.getUserDetail().then(() => this.searchUserDocs());
      } catch (e) {
        console.error(e);
      }
    },
    async searchUserDocs() {
      this.loadingDocs = true;
      try {
        // const qfg = {
        //   operator: "AND",
        //   filters: [
        //     // {
        //     //   filterKey: "nome",
        //     //   filterValue: {value1: this.infos.name.value},
        //     //   operator: Operators.EQUALS.key
        //     // },
        //     // {
        //     //   filterKey: "cognome",
        //     //   filterValue: {value1: this.infos.surname.value},
        //     //   operator: Operators.EQUALS.key
        //     // },
        //     // {
        //     //   filterKey: "fiscalCode",
        //     //   filterValue: {value1: this.infos.fiscalCode.value},
        //     //   operator: Operators.EQUALS.key
        //     // },
        //     {
        //       filterKey: "sign_customer",
        //       filterValue: { value1: this.infos.subNickname.value },
        //       operator: Operators.EQUALS.key,
        //     },
        //   ],
        // };
        const qfg = {
          operator: "AND",
          filters: [
            {
              name: "sign_customer",
              value: this.infos.domainUsername.value,
              operator: Operators.EQUALS.key,
              type: "alfanumerico",
            },
            {
              name: "nome",
              value: this.infos.name.value,
              operator: Operators.EQUALS.key,
              type: "alfanumerico",
            },
          ],
        };
        const res = await searchService.searchDocuments(
          this.companyName,
          qfg,
          1,
          500,
          true,
          true,
          {},
          false
        );
        this.userDocs = res["documents"].map((d) => d.properties);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingDocs = false;
      }
    },
    async downloadDoc(doc) {
      var artifacts = KDocumentDownloadType.ORIGINAL_FILE;
      const ids = [doc.id];
      // const isHistory = this.mIsHistory;
      this.isDownloading = true;
      downloadService
        .downloadDocuments(
          this.$route.params.company,
          this.$i18n.locale,
          "ddMMyyyy",
          true,
          ids,
          artifacts,
          [],
          false
        )
        .finally(() => (this.isDownloading = false));
    },
  },
  created() {
    this.getUserDetail().then(() => this.searchUserDocs());
  },
};
</script>

<style></style>
