<template>
  <section class="section">
    <div class="block">
      <button
        type="button"
        class="button is-primary"
        @click="fetchDossierDocuments"
      >
        <b-icon icon="update"></b-icon>
        <span>{{ $t("buttons.refresh") }}</span>
      </button>
    </div>
    <document-table
      :data="documentsTableContent"
      :tableColumns="documentsTableColumns"
      :isLoadingRows="loadingDossiers"
      :total="totalTablePages"
      :perPage="tablePerPage"
      :totalRecords="totalDocumentNum"
      :downloadTypes="[]"
      :saveState="true"
      :stateKey="mStateKey"
      @row-click="openDocument"
      @page="onTablePage($event)"
    >
    </document-table>
  </section>
</template>

<script>
import DocumentTable from "@/components/DocumentTable/DocumentTable";
//import { commonMixin } from "@/mixins";
import { dossierService, classService } from "@/services";
import { KPropNames } from "@/helpers/constants";
export default {
  components: {
    DocumentTable,
  },
  //mixins: [commonMixin],
  props: {
    companyName: {
      type: String,
      required: true,
    },
    dossierId: {
      required: true,
    },
    stateKey: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loadingDossiers: false,
      tablePage: 1,
      tablePerPage: 10,
      totalTablePages: 0,
      totalDocumentNum: 0,
      documents: [],
    };
  },
  computed: {
    isLoading: function () {
      return this.loadingDossiers;
    },
    mStateKey: function () {
      if (this.stateKey) {
        return `dos.${this.stateKey}`;
      } else {
        return `dos.${this.companyName}.${this.dossierId}`;
      }
    },
    documentsTableColumns: function () {
      const arr = [];
      if (this.documents && this.documents.length > 0) {
        const dos = this.documents[0];
        if (dos) {
          const columns = dos.map((prop) => {
            const obj = {
              label: this.$t(`docclass-properties.${prop.description}`),
              field: prop.name,
            };
            return obj;
          });
          arr.push(...columns);
        }
      }
      return arr;
    },
    documentsTableContent: function () {
      if (this.documents && this.documents.length > 0) {
        return this.documents.map((doc) => {
          const newDoc = {};
          for (let prop of doc) {
            newDoc[prop.name] = prop.value;
          }
          return newDoc;
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    fetchDossierDocuments: function () {
      this.loadingDossiers = true;
      dossierService
        .getDossierDocuments(
          this.companyName,
          this.dossierId,
          this.tablePerPage,
          this.tablePage
        )
        .then((data) => {
          this.documents = data.dossiers;
          this.totalTablePages = data.total_pages;
          this.totalDocumentNum = data.total_results;
        })
        .finally(() => (this.loadingDossiers = false));
    },
    openDocument: async function (rowData) {
      this.loadingDossiers = true;
      try {
        const className = rowData[KPropNames.class_id]; // In class id there is the name of the class
        const docId = rowData[KPropNames.id];
        const classId = this.$store.getters["menu/getClassIdByName"](
          className,
          this.companyName
        );
        console.log(classId);
        /*
      <document-detail
       :classId="classId"
       :company-schema="companyName"
       :documentId="docId"
       :properties="getAllProperties"
       :state-key="stateKey"
      ></document-detail>
      */
        const allProperties = await classService.fetchDocumentProperties(
          this.companyName,
          classId
        );
        console.log(allProperties);
        const tab = {
          id: docId,
          component: "DocumentDetail",
          label: `Doc ${docId}`,
          icon: this.$config.icons.tabs.document_detail,
          props: {
            classId: classId,
            companySchema: this.companyName,
            documentId: docId,
            properties: allProperties,
            stateKey: `${this.companyName}.${className}.${docId}`,
            isHistory: true,
          },
        };
        this.$store.dispatch("tabs/openTab", tab);
      } catch (err) {
        console.error(err);
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t(`error.generic`),
          position: "is-top",
          type: "is-danger",
        });
      } finally {
        this.loadingDossiers = false;
      }
    },
    onTablePage: function (event) {
      this.tablePage = event.page;
      this.tablePerPage = event.rows;
      this.fetchDossierDocuments();
    },
  },
  mounted() {
    this.fetchDossierDocuments();
  },
};
</script>

<style></style>
